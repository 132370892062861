import React from 'react';
/*
To import child routes, insert the following code:
import { routes as <child-folder>Routes } from './components/<folder>';
*/

import { Route } from '@plasma/ui.application.router';

const Launchpad = React.lazy(() => import('.'));

export const LaunchpadRoutes = [
  new Route({
    path: '/launchpad',
    element: Launchpad,
  }),
];
