import { Container } from './SmsSpin.style';
import React from 'react';
interface Props {
  message?: string;
}

export default function SmsSpin(props: Props) {
  return (
    <Container>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 660 660" width="60" height="60">
        <circle
          cx="330"
          cy="330"
          r="300"
          stroke="#c20e1a"
          strokeWidth="50"
          fill="none"
          strokeDasharray="1649.375,235.62"
          strokeDashoffset="0"
          transform="rotate(-45, 330, 330)"
        />
        <circle
          cx="330"
          cy="330"
          r="225"
          stroke="#0475bb"
          strokeWidth="50"
          fill="none"
          strokeDasharray="1237.25,176.75"
          transform="rotate(90, 330, 330)"
        />
        <circle
          cx="330"
          cy="330"
          r="150"
          stroke=" #c20e1a"
          strokeWidth="50"
          fill="none"
          strokeDasharray="824.68,117.8"
          transform="rotate(275, 330, 330)"
        />
        <circle cx="330" cy="330" r="75" stroke="black" strokeWidth="50" fill="black" />
      </svg>
      {props.message ? <span className="loading-message">Loading...</span> : null}
    </Container>
  );
}
