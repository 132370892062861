const convertListToShortString = (itemList: string[]) => {
  let result = ''

  if (itemList.length < 4) {
    result = itemList.join(', ')

  } else {
    result = `${itemList[0]}, ${itemList[1]}, ${itemList[2]}, ...`
  }

  return result
};

export default convertListToShortString;