import baseStyled, { ThemedStyledInterface } from 'styled-components';

import Header from '@plasma/ui.layout.header';

import { ProductsOverviewTheme } from './themes/types';

const styled = baseStyled as ThemedStyledInterface<ProductsOverviewTheme>;

export const ButtonContainer = styled.div`
    .styledButton {
        margin-top: 10px;
        margin-bottom: 30px;
        margin-left: auto;
    }
`;

export const HeaderContainer = styled.div`
    .styledHeader {
        color: #1890ff;
    }
`
