import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react-lite';

import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';

import { PortalLink } from '../../../../applications';
import SmsSpin from '../../../core/SmsSpin/SmsSpin';

export interface WebDisplayFrameProps {
  app: PortalLink;
  value?: string;
  className?: string;
  defaultValue?: string;
  url?: string;
}

type Props = WebDisplayFrameProps & ThemeOverride;

function WebDisplayFrame({ app, size, themeMode }: Props) {
  const [isLoaded, setIsLoaded] = useState(false);

  // there is no way to know if the app is loaded
  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 3000);
  }, []);

  return (
    <ThemeProvider selectedSize={size} selectedMode={themeMode}>
      <div
        style={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: '2',
          height: '100%',
          width: '100%',
        }}
      >
        {!isLoaded && <SmsSpin />}
        <iframe
          src={app.appUrl}
          style={{
            height: isLoaded ? '100%' : '0',
            width: isLoaded ? '100%' : '0',
            border: 'none',
          }}
        />
      </div>
    </ThemeProvider>
  );
}

export default observer(WebDisplayFrame) as unknown as typeof WebDisplayFrame;
