import baseStyled, { ThemedStyledInterface } from 'styled-components';

import Card from '@plasma/ui.display.card/dist/card';

import { ApplicationCardTheme } from './themes/types';

const styled = baseStyled as ThemedStyledInterface<ApplicationCardTheme>;

export const StyledCard = styled(Card)`
  margin: 10px;
  height: 150px;
  width: 300px;
`;

export const StyledIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
  display: inline-block;
`;
