import baseStyled, { ThemedStyledInterface } from 'styled-components';

import { ProductDetailsTheme } from './themes/types';

const styled = baseStyled as ThemedStyledInterface<ProductDetailsTheme>;

export const ButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
`;

export const ProductHeader = styled.h1`
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 24px;
`;

export const ProductDescription = styled.p`
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 1.75;
`;

export const HeaderContainer = styled.div`
    .styledHeader {
        color: #1890ff;
    }
`