import { useEffect, useState } from 'react';

import { useNavigate } from '@plasma/ui.application.router';

import { services } from '../../../api/featureFlagsAgent';
import { ProductDetailsDto } from '../../../models/dtos/feature-flags/ProductDtos';
import { FeatureDto } from '../../../models/dtos/feature-flags/FeatureDtos';
import { FeatureFlagDetailsDto } from '../../../models/dtos/feature-flags/FeatureFlagDto';

const useSingleProductDetailsHook = (productId: string) => {
  const [singleProduct, setSingleProduct] = useState<ProductDetailsDto>();
  const [featureFlags, setFeatureFlags] = useState<FeatureFlagDetailsDto[]>([]);
  const navigate = useNavigate();

  const fetchSingleProductWithFeatures = async () => {
    try {
      const singleProductData = (await services.product.getById(productId)).data;
      const featuresData = (await services.features.getAll()).data;
      const featureFlagsData = (await services.featureFlags.getAll()).data;
  
      const filteredFeatures = featuresData.filter((feature) => feature.productId === productId);
      singleProductData.features = filteredFeatures;
  
      setSingleProduct(singleProductData);
      setFeatureFlags(featureFlagsData);

    } catch (error) {
      console.error('Error fetching single product data:', error);
    }
  };

  useEffect(() => {
    fetchSingleProductWithFeatures();
  }, []);

  const buildFeatureColumns = () => {
  
    if (singleProduct?.features) {
      const columns = singleProduct?.features?.map(({ name, id }) => ({
        id: id,
        name: name,
        featureFlag: featureFlags.find((featureFlag) => featureFlag.featureId === id),
      }));

      return columns;
    } else {
      return [];
    }

  };

  const featureColumns = buildFeatureColumns();

  const handleNavigate = (path: string, ) => {
    navigate(path);
  };

  const onCreateFeature = async (data: FeatureDto) => {
    data.productId = productId;
    await services.features.create(data);
    await fetchSingleProductWithFeatures();
  };

  const onDeleteFeature = async (id: string) => {
    await services.features.delete(id);
    await fetchSingleProductWithFeatures(); 
  };

  const onUpdateFeatureFlag = async (id: string, currentStatus: boolean) => {
    await services.featureFlags.update({ featureFlagId: id, isEnabled: !currentStatus });
    await fetchSingleProductWithFeatures();
  }

  return {
    featureColumns,
    singleProductName: singleProduct?.name,
    singleProductDescription: singleProduct?.description,
    handleNavigate,
    onCreateFeature,
    onDeleteFeature,
    onUpdateFeatureFlag,
  };
};

export default useSingleProductDetailsHook;
