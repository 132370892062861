import axios from 'axios';

import { ProductDetailsDto, ProductDto } from '../models/dtos/feature-flags/ProductDtos';
import { FeatureDetailsDto, FeatureDto } from '../models/dtos/feature-flags/FeatureDtos';
import { FeatureFlagDetailsDto, FeatureFlagDto } from '../models/dtos/feature-flags/FeatureFlagDto';

const axiosInstance = axios.create();

//@ts-ignore
axiosInstance.defaults.baseURL = window.env.BACKEND_URL;

const v2Header = {
        'X-Version': '2',
}

const product = {
    getAll: () => axiosInstance.get<ProductDetailsDto[]>('admin/products'),
    getById: (id: string) => axiosInstance.get<ProductDetailsDto>(`admin/products/${id}`),
    getFeaturesByProductId: (id: string) => axiosInstance.get<FeatureDetailsDto[]>(`admin/products/${id}/features`),
    create: (data: ProductDto) => axiosInstance.post<ProductDto>('admin/products', data),
    delete: (objectId: string) => axiosInstance.delete('admin/products/', { params: {id: objectId }}),
}

const features = {
    getAll: () => axiosInstance.get<FeatureDetailsDto[]>('admin/features', { headers: v2Header}),
    getById: (id: string) => axiosInstance.get<FeatureDetailsDto>(`admin/features/${id}`, { headers: v2Header }),
    create: (data: FeatureDto) => axiosInstance.post<FeatureDto>('admin/features', data, { headers: v2Header }),
    delete: (objectId: string) => axiosInstance.delete('admin/features/', { 
        params: {id: objectId },
        headers: v2Header,
    }),
}

const featureFlags = {
    getAll: () => axiosInstance.get<FeatureFlagDetailsDto[]>('admin/feature-flags', { headers: v2Header }),
    update: (data: FeatureFlagDto) => axiosInstance.put<FeatureFlagDto>('admin/feature-flags', data, { headers: v2Header }),
}

const services = { product, features, featureFlags };

export { services, axiosInstance };
