import React, { Dispatch } from "react";

import Form from '@plasma/ui.input.form';
import FormField from '@plasma/ui.input.form-field';
import TextInput from '@plasma/ui.input.text-input';

import { FeatureDto } from "../../../../models/dtos/feature-flags/FeatureDtos";

interface Props {
    formData: FeatureDto,
    setFormData: Dispatch<any>,
}


function FeatureCreateForm(props: Props) {

    return (
        <Form
            onChange={ (data: Partial<FeatureDto>) => {props.setFormData(data);
        } }
            >
            <FormField required label="Name" name="name" hint="Name of the new Product">
                <TextInput value={props.formData.name}/>
            </FormField>
        </Form>
    )
}

export default FeatureCreateForm;