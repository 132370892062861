import { MenuItem } from '@plasma/ui.application.app-provider';

export const menuItems: MenuItem[] = [
  {
    label: 'Launchpad',
    icon: 'Home',
    path: '/launchpad',
  },
  {
    label: 'Feature Flags',
    icon: 'base--h_rectangle_crop--top_right',
    path: '/feature-flags',
  },

];
