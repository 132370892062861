import baseStyled, { ThemedStyledInterface } from 'styled-components';

import { LaunchpadTheme } from './themes/types';

const styled = baseStyled as ThemedStyledInterface<LaunchpadTheme>;

export const MainContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

export const ApplicationListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
  overflow-y: auto;
  flex: 1;
  height: 100%;
`;

export const SingleApplicationContainer = styled.div`
  background-color: #eee;
  border-radius: 5px;
  flex: 5;
  display: flex;
  padding: 10px;
`;

export const ApplicationIframe = styled.iframe`
  flex: 1;
  border: 0;
`;
