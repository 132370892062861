import { useCallback, useEffect, useState } from 'react';

import { useHelpBundle } from '@plasma/ui.application.app';
import { useResourceBundle, useTranslation } from '@plasma/ui.utils.i18n';
import { LocalStorageSettingsStore } from '@plasma/ui.utils.settings-store';

import translations from './i18n';
import help from './launchpad.help';

export interface PortalLink {
  title: string;
  appUrl: string;
  resourceFilter?: string;
  version?: string;
  description?: string;
  iconUrl?: string;
  isExternal?: boolean;
}

export type DataType = {
  description: string;
  method: string;
  path: string;
  protocol: string;
  requestBody: string;
  status: boolean;
  namespace: string;
  name: string;
  requests: number;
  errorRate: number;
};

function useLaunchpadHook() {
  useResourceBundle(translations, undefined, '40acb31e-71f7-45bd-b0c2-a47ccf7d5f0c');
  useHelpBundle(help);
  const { t } = useTranslation();
  const [launchedApps, setLaunchedApps] = useState<PortalLink[]>([]);
  const [selectedTab, setSelectedTab] = useState<string>();
  const [apps, setApps] = useState<PortalLink[]>([]);

  const initApps = useCallback(async () => {
    const response = await fetch('/config/clients.json');
    const loadedApps = (await response.json()) as PortalLink[];
    setApps(loadedApps);
  }, [setApps]);

  useEffect(() => {
    initApps();
  }, [initApps]);

  const launchApplication = (app: PortalLink) => {
    if (!app.isExternal) {
      setLaunchedApps((prev) => [...prev, app]);
      setSelectedTab(app.title);
    } else {
      window.open(app.appUrl, '_blank', 'noreferrer');
    }
  };

  const closeApp = (targetKey: React.MouseEvent | React.KeyboardEvent | string) => {
    setLaunchedApps(launchedApps.filter((app) => app.title !== targetKey));
    setSelectedTab(undefined);
  };

  LocalStorageSettingsStore;
  return {
    t,
    apps,
    launchedApps,
    launchApplication,
    closeApp,
    selectedTab,
    setSelectedTab,
  };
}

export default useLaunchpadHook;
