import React, { memo } from 'react';

import { useKeycloak } from '@react-keycloak/web';
import Tabs from 'antd/lib/tabs';

import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';

import ApplicationCard from '../../components/features/ApplicationCard/ApplicationCard';
import { WebDisplayFrame } from '../../components/features/display-frames/WebDisplayFrame';
import useLaunchpadHook, { PortalLink } from './launchpad.hook';
import { ApplicationListContainer, MainContainer } from './launchpad.styles';
import themes from './themes';

function Launchpad(props: ThemeOverride) {
  const { size, themeMode } = props;
  const { apps, launchApplication, launchedApps, closeApp, selectedTab, setSelectedTab } = useLaunchpadHook();
  const { keycloak } = useKeycloak();

  const isAppPermitted = (app: PortalLink) => {
    if (!app.resourceFilter) {
      return true;
    }
    return keycloak.resourceAccess && app.resourceFilter in keycloak.resourceAccess;
  };

  return (
    <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
      <MainContainer>
        <Tabs
          type="editable-card"
          onChange={() => {}}
          onEdit={closeApp}
          hideAdd
          activeKey={selectedTab}
          onTabClick={(key) => setSelectedTab(key)}
          moreIcon={false}
        >
          <Tabs.TabPane tab="Launchpad" key="launchpad" closable={false}>
            <ApplicationListContainer>
              {apps
                .filter(isAppPermitted)
                .map((app) => (
                  <ApplicationCard key={app.title} app={app} onSelect={() => launchApplication(app)} />
                ))}
            </ApplicationListContainer>
          </Tabs.TabPane>
          {launchedApps &&
            launchedApps.map((launchedApp) => (
              <Tabs.TabPane tab={launchedApp.title} key={launchedApp.title}>
                <WebDisplayFrame app={launchedApp} />
              </Tabs.TabPane>
            ))}
        </Tabs>
      </MainContainer>
    </ThemeProvider>
  );
}

export default memo(Launchpad) as unknown as typeof Launchpad;
