import { Help } from '@plasma/ui.application.app';

const help: Help[] = [
  {
    title: 'Overview',
    description: 'description of Overview',
    url: '/overview',
  },
];

export default help;
