import React, { Dispatch } from "react";

import Form from '@plasma/ui.input.form';
import FormField from '@plasma/ui.input.form-field';
import TextInput from '@plasma/ui.input.text-input';

import { ProductDto } from "../../../../models/dtos/feature-flags/ProductDtos";

interface Props {
    formData: ProductDto,
    setFormData: Dispatch<any>,
}


function ProductCreateForm(props: Props) {

    return (
        <Form
            onChange={ (data: Partial<ProductDto>) => {props.setFormData(data);
        } }
            >
            <FormField required label="Name" name="name" hint="Name of the new Product">
                <TextInput value={props.formData.name}/>
            </FormField>
            <FormField label="Description" name="description" hint="Description of the new Product">
                <TextInput value={props.formData.description}/>
            </FormField>
        </Form>
    )
}

export default ProductCreateForm;