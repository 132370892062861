import React from 'react';

import { Navigate, Route, RouteObject } from '@plasma/ui.application.router';

import { LayoutRoutes } from './components/layouts/layout';
import ProductsOverview from './pages/feature-flags/products-overview/products-overview';
import ProductDetails from './pages/feature-flags/product-details/product-details';

const getRoutes = (keycloak: any) => {
  //@ts-ignore
  const featureFlagsActivated = JSON.parse(window.env.ACTIVATE_FEATURE_FLAGS);
  const featureFlagsAllowed = "feature-flags" in keycloak.resourceAccess;

  const routes: RouteObject[] = [
    //@ts-ignore
    new Route({
      path: '/',
      element: () => <Navigate to="/launchpad" replace />,
    }),
    //@ts-ignore
    LayoutRoutes,
    //@ts-ignore
    ...(featureFlagsActivated && featureFlagsAllowed
      ? [
        new Route({
          path: '/feature-flags',
          element: ProductsOverview,
        }),
        new Route({
          path: '/feature-flags/product-details',
          element: ProductDetails,
        }),
        ]
      : []),
  ];

  return routes;
};

export default getRoutes;
