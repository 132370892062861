import React from 'react';
import { Route } from '@plasma/ui.application.router';
import { LaunchpadRoutes } from '../../../pages/launchpad';

const Layout = React.lazy(() => import('.'));

export const LayoutRoutes = new Route({
  path: '',
  element: Layout,
  children: [
    ...LaunchpadRoutes
  ],
});
