import { useEffect, useState } from 'react';
import { services } from '../../../api/featureFlagsAgent';
import { ProductDetailsDto, ProductDto } from '../../../models/dtos/feature-flags/ProductDtos';
import convertListToShortString from '../../../utils/list-shortifier';
import { useNavigate } from '@plasma/ui.application.router';

const useProductHook = () => {
  const [products, setProducts] = useState<ProductDetailsDto[]>([]);
  const navigate = useNavigate();

  const fetchProductsData = async () => {
    try {
      const productsResponse = await services.product.getAll();
      const productsData = productsResponse.data;

      for (const element of productsData) {
        const featuresResponse = await services.product.getFeaturesByProductId(element.id);
        element.features = featuresResponse.data
      }

      setProducts(productsData);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchProductsData();
  }, []);

  const buildProductsColumns = () => {

    const columns = products.map(({ name, id, features }) => ({
      name: name,
      id: id,
      features: convertListToShortString(features?.map(feature => feature.name) ?? [] )
    }));

    return columns
  };

  const productColumns = buildProductsColumns();

  const onCreateProduct = async (data: ProductDto) => {
    await services.product.create(data);
    await fetchProductsData();
  };

  const onDeleteProduct = async (id: string) => {
    await services.product.delete(id);
    await fetchProductsData(); 
  };

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return {
    onCreateProduct,
    onDeleteProduct,
    handleNavigate,
    productColumns,
  };
};

export default useProductHook;
