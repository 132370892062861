import React, { useState } from 'react';

import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';
import Header from '@plasma/ui.layout.header'
import DataGrid from '@plasma/ui.display.data-grid';
import Page from '@plasma/ui.layout.page';
import Button from '@plasma/ui.general.button'
import { useLocation } from '@plasma/ui.application.router';
import { Switch } from '@plasma/ui.input.switch';
import { Modal } from 'antd';

import themes from './themes';
import useProductDetailsHook from './product-details.hook';
import { FeatureDto } from '../../../models/dtos/feature-flags/FeatureDtos';
import FeatureCreateForm from '../../../components/features/feature-flags-builder/FeatureCreateForm/FeatureCreateForm';
import { ButtonContainer, ProductHeader, ProductDescription, HeaderContainer } from './product-details.styles';

function ProductDetails(props: ThemeOverride) {
  const { size, themeMode } = props;
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const productId = queryParams.get('productId');
  const [featureCreateData, setFeatureCreateData] = useState<FeatureDto>({name: '', productId: ''});
  const [featureDeleteModalOpen, setFeatureDeleteModalOpen] = useState<boolean>(false);
  const [featureCreateModalOpen, setFeatureCreateModalOpen] = useState<boolean>(false);

  class FeatureToDelete {
    id: string;
    name: string;

    constructor(id: string, name: string) {
      this.id = id;
      this.name = name;
    }
  }
  const [featureToDelete, setFeatureToDelete] = useState<FeatureToDelete>(new FeatureToDelete('', ''))

  const logic = useProductDetailsHook(productId!);

  const productsColumns = [
    {
      Header: 'Feature',
      accessor: 'name',
    },
    {
      Header: 'Feature Flag',
      accessor: 'featureFlag',
      Cell: ({ value }: { value: { id: string; isEnabled: boolean } }) => {
        if (!value) {
          return null;
        }
        return (
          <Switch
            checked={value.isEnabled}
            onChange={() => {
            logic.onUpdateFeatureFlag(value.id, value.isEnabled);
          }}
          />
        )
      }
    },
    {
      Header: 'Actions',
      accessor: 'id',
      Cell: ({ row }: { row: any }) => {
        return (
          <Button
            prefixIcon={{ name: 'delete' }}
            onClick={() => {
              setFeatureToDelete(new FeatureToDelete(row.values.id, row.values.name))
              setFeatureDeleteModalOpen(true);
            } }
            type='tertiary'
            title={'Delete Feature'} 
          />
        );
      }
    }
  ]

  return(
    <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
      <Page id="product-details" backgroundType="lit" header={<HeaderContainer><Header className="styledHeader" title="Feature Flags" /></HeaderContainer>}>
        <ButtonContainer>
          <Button title="Back to Products" prefixIcon={{ name: 'ArrowBackIcon' }} onClick={ () => logic.handleNavigate("/feature-flags")}/>
          <Button title="Create Feature" onClick={ () => setFeatureCreateModalOpen(true)}/>
        </ButtonContainer>
        <ProductHeader>{logic.singleProductName}</ProductHeader>
        <ProductDescription>{logic.singleProductDescription}</ProductDescription>
        <DataGrid
          columns={productsColumns}
          data={logic.featureColumns}
          disableToolHeader
          disableGroupBy
          disableRowsSelection
          disableDragAndDrop
          disablePagination
        />
        <Modal
          title="Create Feature"
          visible={featureCreateModalOpen}
          onOk={ () => { logic.onCreateFeature(featureCreateData); setFeatureCreateModalOpen(false) }}
          onCancel={ () => setFeatureCreateModalOpen(false)}
          okText="Save"
          cancelText="Discard"
          maskClosable={true}
          closable={true}
        >
          <FeatureCreateForm formData={featureCreateData} setFormData={setFeatureCreateData}/>
        </Modal>
        <Modal
          title="Delete Feature"
          visible={featureDeleteModalOpen}
          onOk={ () => { 
            logic.onDeleteFeature(featureToDelete.id); 
            setFeatureDeleteModalOpen(false); 
            setFeatureToDelete(new FeatureToDelete('', ''));
          }}
          onCancel={ () => setFeatureDeleteModalOpen(false)}
          okText="Yes"
          cancelText="No"
          okType='danger'
          maskClosable={true}
          closable={true}
        >
          <p>This action will delete the <strong>"{featureToDelete.name}"</strong> feature.</p>
          <p>Are you sure you want to continue?</p>
        </Modal>
      </Page>
    </ThemeProvider>
  );
}

export default ProductDetails;
