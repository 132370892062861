import React from 'react';

import Button from '@plasma/ui.general.button';

import { PortalLink } from '../../../pages/launchpad/launchpad.hook';
import * as styled from './ApplicationCard.styles';
//@ts-ignore
import defaultIcon from '@public/defaultIcon.svg';

export interface ApplicationCardProps {
  app: PortalLink;
  onSelect: () => void;
}

const ApplicationCard = ({ app, onSelect }: ApplicationCardProps) => {
  return (
    <styled.StyledCard
      metaCardClassType={'metaCreationCard'}
      meta={{
        title: (
          <>
            <styled.StyledIcon src={app.iconUrl ?? defaultIcon} />
            {app.title}
          </>
        ),
        subtitle: app.description,
      }}
      actions={[<Button key={1} type="primary" title="Launch" onClick={onSelect} />]}
    />
  );
};

export default ApplicationCard;
