import React, { useState } from 'react';

import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';
import Header from '@plasma/ui.layout.header'
import DataGrid from '@plasma/ui.display.data-grid';
import Page from '@plasma/ui.layout.page';
import Button from '@plasma/ui.general.button'
import { Modal } from 'antd';

import themes from './themes';
import useProductHook from './products-overview.hook';
import ProductCreateForm from '../../../components/features/feature-flags-builder/ProductCreateForm/ProductCreateForm';
import { ProductDto } from '../../../models/dtos/feature-flags/ProductDtos';
import { ButtonContainer, HeaderContainer } from './products-overview.styles';

function ProductsOverview(props: ThemeOverride) {
  const { size, themeMode } = props;
  const [productCreateData, setProductCreateData] = useState<ProductDto>({
    name: "",
  })
  const [productCreateModalOpen, setProductCreateModalOpen] = useState<boolean>(false)
  const [productDeleteModalOpen, setProductDeleteModalOpen] = useState<boolean>(false)
  class ProductToDelete {
    id: string;
    name: string;

    constructor(id: string, name: string) {
      this.id = id;
      this.name = name;
    }
  }
  const [productToDelete, setProductToDelete] = useState<ProductToDelete>(new ProductToDelete('', ''))

  const logic = useProductHook();

  const productsColumns = [
      {
        Header: 'Product',
        accessor: 'name',
      },
      {
        Header: 'Features',
        accessor: 'features',
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: ({ row }: { row: any }) => {
          return (
            <div style={{ display: 'flex', gap: '10px' }}>
              <Button
                prefixIcon={{ name: 'delete' }}
                onClick={() => {
                  setProductToDelete(new ProductToDelete(row.values.id, row.values.name))
                  setProductDeleteModalOpen(true);
                } }
                type='tertiary'
                title={'Delete Product'} 
              />
              <Button
                onClick={() => {
                  logic.handleNavigate(`/feature-flags/product-details/?productId=${row.values.id}`);
                } }
                type='tertiary'
                title={'Product Details'}
              />
            </div>
          );
        }
      }
    ]

  return (
    <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
      <Page id="products-overview" backgroundType="lit" header={<HeaderContainer><Header className="styledHeader" title="Feature Flags" /></HeaderContainer>}>
        <ButtonContainer>
          <Button className='styledButton' title="Create Product" onClick={ () => setProductCreateModalOpen(true)}/>
        </ButtonContainer>
        <DataGrid
          columns={productsColumns}
          data={logic.productColumns}
          disableToolHeader
          disableGroupBy
          disableRowsSelection
          disableDragAndDrop
          disablePagination
        />
        <Modal
          title="Create Product"
          visible={productCreateModalOpen}
          onOk={ () => { logic.onCreateProduct(productCreateData); setProductCreateModalOpen(false) }}
          onCancel={ () => setProductCreateModalOpen(false)}
          okText="Save"
          cancelText="Discard"
          maskClosable={true}
          closable={true}
        >
          <ProductCreateForm formData={productCreateData} setFormData={setProductCreateData}/>
        </Modal>
        <Modal
          title="Delete Product"
          visible={productDeleteModalOpen}
          onOk={ () => { 
            logic.onDeleteProduct(productToDelete.id); 
            setProductDeleteModalOpen(false); 
            setProductToDelete(new ProductToDelete('', ''));
          }}
          onCancel={ () => setProductDeleteModalOpen(false)}
          okText="Yes"
          cancelText="No"
          okType='danger'
          maskClosable={true}
          closable={true}
        >
          <p>This action will delete the <strong>"{productToDelete.name}"</strong> product.</p>
          <p>Are you sure you want to continue?</p>
        </Modal>
      </Page>
    </ThemeProvider>
  );
}

export default ProductsOverview;
